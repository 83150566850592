import React from "react";
import { StaticImage } from "gatsby-plugin-image"
// import BannerVideo from "../../images/sirius_video_dubai.mp4"

const HomeBannerImage = (props) => {

    return (
        // <StaticImage src="../../images/home_banner.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid banner-img" />
        <video
            src={"https://ggfx-oneooneproperties.s3.eu-west-2.amazonaws.com/i/home_page_video_1b33568305.webm?updated_at=2024-10-08T08:21:24.814Z"}
            alt={`${process.env.GATSBY_SITE_NAME} - Home`}
            className="banner-img"
            autoPlay="autoplay"
            ref={props.videoRef}
            playsInLine="playsinline"
            loop="true"
            muted="true"
        />
    )

}

export default HomeBannerImage